<template>
    <div>
        <nl-loader v-if="preloading" :show="true"/>

        <div v-else class="row px-2 px-md-4 overflow-x-auto">


            <div class="mb-3 d-flex justify-content-end w-100">
                <b-dropdown aria-role="list" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            label="Exports"
                            type="is-warning"
                            :icon-right="active ? 'caret-up' : 'caret-down'"/>
                    </template>
                    <b-dropdown-item aria-role="listitem" class="mr-3 " @click="doExport">
                        <div class="media is-flex py-2">
                            <b-icon class="media-left align-self-center" icon="file-download" size="is-large"></b-icon>
                            <div class="media-content">
                                <h3>Export XML</h3>
                                <small>Exporter les ordres de virement et marquer les factures comme étant
                                    payées</small>
                            </div>
                        </div>
                    </b-dropdown-item>

                </b-dropdown>
            </div>

            <div v-for="(withdrawal, index) in withdrawals" :key="index" class="box col-12 mb-4 p-4">
                <CardWithdrawalWinnings :data="withdrawal" :withdraw_date="index"/>
            </div>
        </div>
        <Toaster ref="toaster"/>
    </div>
</template>

<script>
import Toaster from '@/components/UI/Toaster'
import {mapActions, mapState} from "vuex";
import Download from "@/utils/download";

export default {
    title: 'withdrawal-winnings.title',
    name: 'WithdrawalWinnings',

    components: {
        Toaster
    },

    data: function () {
        return {
            preloading: true,

            withdrawals: []
        }
    },

    created() {
        let withdrawals = this.axios.get('/withdrawals').then(response => {
            this.withdrawals = response.data.withdrawals
        })

        Promise.all([withdrawals]).then(() => {
            this.preloading = false
        })
    },

    computed: {},

    methods: {
        ...mapActions('billing', ['exportXML']),
        doExport() {
            this.exportXML().then((response) => {
                Download.download(
                    response,
                    `virements_${this.moment().format('YYYY-MM-DD')}`
                )
            }).catch((e) => {
                this.$store.dispatch('toastError', e)
            })
        }
    }
}
</script>


<style scoped>
:deep(.dropdown-content) {
    padding: 0;
}

:deep(.dropdown-menu) {
    padding: 0;
}

:deep(.dropdown-item) {
    border-bottom: 1px solid #dee2e6;
}

:deep(.dropdown-item:last-child) {
    border-bottom: none;
}


</style>